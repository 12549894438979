
import { MSG_CODE } from '../constants';
import { captureScreenshot, setRoomElementsForOrbit } from './viewerModuleController';
import { ACTION_CODE } from '../../../action/src/actionConstants';
import { getSceneObject, navigateToObject, playAnimationByAction, playeAnimationModelByName } from './objectsModuleController';
import { connectToRoom, disconnect } from './networkingModuleController';
import { enableAudio, leaveChannel, rejoinChannel } from './communicationModuleController';
import { enablePlayerMovementAndRotation, exitOrbitMode, lookAtPosition, moveToPosition, orbitToSceneObject, rotateCamera360, selectSceneObject } from './navigationModuleController';


export let actionPromises = {};
let options;//todas las funciones estan en el dom
let actionModule;
let networkingModule;
let navigationModule;
let communicationModule;
let followUser;
let isFollowing = false;
let isHost = false;
let isFreeze = false;
let isMuteHost = false;

var orbitResolution = null;

export function initActionModule(_options) {
    return new Promise((resolve, reject) => {
        options = _options;
        actionModule = options.modules.actionModule;
        networkingModule = options.modules.networkingModule;
        communicationModule = options.modules.communicationModule;
        navigationModule = options.modules.navigationModule;
        resolve();
    });
}

export function actionHandler(content) {
    switch (content.action) {
        case "gather":
            var _msg = {
                code: MSG_CODE.ACTION_MOVE_TO,
                data: {
                    position: content.position,
                    positionLook: content.positionLook || { x: 0, y: 1, z: 0 }
                }
            }
            // options.workers.offscreenCanvasWorker.postMessage(_msg);
            moveToPosition(_msg).then(() => { });
            break;
        case "lookTo":
            var _msg = {
                code: MSG_CODE.ACTION_LOOKTO_ORDER,
                data: {
                    positionLook: content.positionLook || { x: 0, y: 1, z: 0 }
                }
            }
            // options.workers.offscreenCanvasWorker.postMessage(_msg);
            lookAtPosition(_msg).then(() => { });

            break;
        case "pattern":
            var _msg = {
                code: MSG_CODE.ACTION_MOVE_TO,
                data: {
                    position: content.position,
                    positionLook: content.positionLook || { x: 0, y: 1, z: 0 }
                }
            }
            // options.workers.offscreenCanvasWorker.postMessage(_msg);
            moveToPosition(_msg).then(() => { });

            break;
        case "followme":
            options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_FOLLOW_USER, userId: content.followUser, index: content.index, length: content.length });
            break;
        case "unfollow":
            options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_UNFOLLOW_USER });
            break;
        case "orbit":
            if (content.position !== null && content.position !== undefined) {
                options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_ORBIT_POSITION, position: content.position });
                options.callbacks.defaultResponse({ code: MSG_CODE.ACTION_ORBIT_POSITION, position: content.position });
            }
            break;
        case "freeze":
            isFreeze = true;
            enablePlayerMovementAndRotation({ data: { enabled: false } });
            options.callbacks.defaultResponse({ code: 'onFreeze' });
            break;
        case "unFreeze":
            isFreeze = false;
            enablePlayerMovementAndRotation({ data: { enabled: true } });
            options.callbacks.defaultResponse({ code: 'onUnfreeze' });
            break;
        case "message":
            const data = { code: MSG_CODE.ACTION_MESSAGE_POPUP, data: content };
            options.callbacks.defaultResponse(data);
            break;
        case "mute":
            isMuteHost = true;
            //enableAudio(false);
            const data1 = { code: MSG_CODE.ACTION_MUTE, data: content };
            options.callbacks.defaultResponse(data1);
            break;
        case "unmute":
            isMuteHost = false;
            //enableAudio(true);
            const data2 = { code: MSG_CODE.ACTION_UNMUTE, data: content };
            options.callbacks.defaultResponse(data2);
            break;
        default:
            break;
    }
}

export function moveWithPathfindingFinished() {
    const data = {
        code: "onPathfindingFinished",
        data: {}
    };
    options.callbacks.defaultResponse(data);
}

export function rotateCamera(msg) {
    const data = {
        code: "onRotateCamera",
        data: {}
    };
    options.callbacks.defaultResponse(data);
}

export async function loadPresentation(content) {
    return new Promise((resolve, reject) => {

        enablePlayerMovementAndRotation({ data: { enabled: false, allowCameraRotation: true } }).then(async () => {
            for (let index = 0; index < content.actions.length; index++) {
                const action = content.actions[index];
                await loadActions(action);
                await delay(1000);
            }
            enablePlayerMovementAndRotation({ data: { enabled: true, allowCameraRotation: true } });
            resolve();
        });
    });
}

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// function processActions() {
//     if (currentIndex < currentActions.length) {
//         actionFinish = false;
//         loadActions(currentActions[currentIndex]);
//         currentIndex++;
//     } else {
//         enablePlayerMovement(true);
//     }
// }

export function loadActions(content) {
    return new Promise((resolve, reject) => {

        if (content) {
            switch (content.value) {
                case ACTION_CODE.LOAD_SCENE:
                    break;
                case ACTION_CODE.NAVIGATE_TO_POSITION:
                    if (content.position) {
                        var _msg = {
                            code: MSG_CODE.ACTION_MOVE_TO,
                            data: {
                                position: content.position,
                                positionLook: content.positionLook || { x: 0, y: 1, z: 0 }
                            }
                        }
                        moveToPosition(_msg).then(() => { resolve(); });
                    }
                    break;
                case ACTION_CODE.NAVIGATE_TO_OBJECT:
                    console.log(content.target);

                    if (content.target) {
                        // let sceneObjectFound = getSceneObject(content.target);
                        // console.log(sceneObjectFound);

                        // if (sceneObjectFound && sceneObjectFound.data.position) {
                        //     var _msg = {
                        //         code: MSG_CODE.ACTION_MOVE_TO,
                        //         data: {
                        //             position: { x: sceneObjectFound.data.position[0], y: sceneObjectFound.data.position[1], z: sceneObjectFound.data.position[2] },
                        //             positionLook: sceneObjectFound.positionLook || { x: 0, y: 1, z: 0 }
                        //         }
                        //     }
                        //     moveToPosition(_msg).then(() => { resolve(); });
                        // }
                        navigateToObject({ uuid: content.target }).then(() => { resolve(); });
                    }

                    break;
                case ACTION_CODE.ORBIT_OBJECT:

                    console.log(content.target);
                    if (content.target) {

                        selectSceneObject(content.target).then(() => {

                            orbitToSceneObject({ data: { uuid: content.target } }).then();
                            setRoomElementsForOrbit({ data: { uuid: content.target, isVisible: false } }).then();

                            const data = {
                                code: "onForceOrbitObject",
                                data: {}
                            };
                            options.callbacks.defaultResponse(data);

                            orbitResolution = { resolve, uuid: content.target };

                        });

                        // setTimeout(() => {
                        //     exitOrbitMode({ data: { uuid: content.target } }).then(() => { resolve(); });
                        // }, content.duration * 1000);
                    }

                    break;
                case ACTION_CODE.ENABLE_SINGLE_USER_EXPERIENCE:
                    disconnect();
                    leaveChannel();
                    resolve();
                    break;
                case ACTION_CODE.DISABLE_SINGLE_USER_EXPERIENCE:
                    connectToRoom();
                    rejoinChannel();
                    resolve();
                    break;
                case ACTION_CODE.REMOVE_FROM_COMMUNICATIONS_CHANNEL:
                    leaveChannel();
                    resolve();
                    break;
                case ACTION_CODE.REMOVE_FROM_NETWORKING_ROOM:
                    disconnect();
                    resolve();
                    break;
                case ACTION_CODE.SHOW_ALERT:
                    resolve();
                    break;
                case ACTION_CODE.LOOK_AT_POSITION:
                    if (content.position) {
                        var _msg = {
                            code: MSG_CODE.ACTION_LOOKTO_ORDER,
                            data: {
                                positionLook: content.position
                            }
                        }
                        lookAtPosition(_msg).then(() => { resolve(); });
                    }
                    break;
                case ACTION_CODE.SHOW_MODAL:
                    resolve();
                    break;
                case ACTION_CODE.ADD_METADATA:
                    resolve();
                    break;
                case ACTION_CODE.ROTATE_360:
                    rotateCamera360().then(() => { resolve(); });
                    break;
                case ACTION_CODE.ANIMATE_OBJECT:

                    let sceneObjectFound = getSceneObject(content.target);
                    if (sceneObjectFound) {
                        //   animateObject(sceneObjectFound.uuid, content.animation).then(() => { resolve(); });
                        console.log(sceneObjectFound);
                        const MSG = {
                            data: {
                                uuid: sceneObjectFound.uuid,
                                action: "playAnimation",
                                index: 0,
                                loopType: "loopAll"
                            }
                        }
                        playAnimationByAction(sceneObjectFound, MSG);

                    }
                    resolve();
                    break;
                default:
            }
        }

        // resolve();
    });

}

export function exitOrbitByPresentation() {
    if (orbitResolution) {
        exitOrbitMode({ data: { uuid: orbitResolution.uuid } }).then(() => {
            orbitResolution.resolve();
            orbitResolution = null;
        });
        setRoomElementsForOrbit({ data: { uuid: orbitResolution.uuid, isVisible: true } }).then();
    }
}

export function getCaptureScreenshot() {
    return new Promise((resolve, reject) => {
        captureScreenshot().then((data) => {
            const bitmap = data.bitmap;

            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = bitmap.width;
            canvas.height = bitmap.height;
            ctx.drawImage(bitmap, 0, 0);
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = data.filename;
            link.click();
            resolve();
        })
    });
}
// export function pathfindingFinished() {
//     if (isloadPresentation) {
//         actionFinish = true;
//         processActions();
//     }
// }

export function hostActionReciever(data) {
    if (!isHost) return;
    let actordatalist = hostActorsData(data);
    actordatalist.forEach(actor => {
        let actornr = networkingModule.searchActorByUserId(actor.actorNr);
        networkingModule.sendMessage(MSG_CODE.ACTION_SEND_ORDER, actor, [actornr.actorNr]);
    });
}
export function hostActorsData(data) {
    let actordatalist;
    switch (data.action) {
        case ACTION_CODE.GATHER:
            actordatalist = actionModule.gather(data.users, data.position);
            break;
        case ACTION_CODE.LOOK:
            actordatalist = actionModule.lookTo(data.users, data.position);
            break;
        case ACTION_CODE.PATTERN:
            actordatalist = actionModule.pattern(data.users, 'star', data.position);
            break;
        case ACTION_CODE.ORBIT:
            actordatalist = actionModule.orbit(data.users, data.position);
            break;
        case ACTION_CODE.FREEZE:
            actordatalist = actionModule.freeze(data.users);
            break;
        case ACTION_CODE.UNFREEZE:
            actordatalist = actionModule.unFreeze(data.users);
            break;
        case ACTION_CODE.FOLLOW:
            actordatalist = actionModule.follow(data.users);
            const localActor = networkingModule.getLocalActor();
            actordatalist.forEach(actor => { actor.followUser = localActor.userId; });
            break;
        case ACTION_CODE.UNFOLLOW:
            actordatalist = actionModule.unFollow(data.users);
            break;
        case ACTION_CODE.MUTE:
            actordatalist = actionModule.muteUsers(data.users);
            isMuteHost = true;
            break;
        case ACTION_CODE.UNMUTE:
            actordatalist = actionModule.unMuteUsers(data.users);
            isMuteHost = false;
            break;

        default:
            return actordatalist = [];
    }
    return actordatalist;
}
export function pointerReceiver(data) {
    if (data.users == null && data.users == undefined) return;
    if (data.action !== 'gather' && data.action !== 'pattern' && data.action !== 'look' && data.action !== 'orbit') return;
    let actordatalist = hostActorsData(data);
    actordatalist.forEach(actor => {
        actor.position.x = data.position.x;
        //actor.position.y = data.position.y - data.position.y;
        actor.position.z = data.position.z;

    });
    options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_POSITION_INDICATOR, data: actordatalist });

}

export function setHost(value) {
    isHost = value;
}

export function changeStateActionCancel() {
    options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_CANCEL, data: { state: "movement" } });
}

export function getIsFreeze() {
    return isFreeze;
}

export function getIsMuteHost() {
    return isMuteHost;
}

export function sendMessageToPlayer(message, users) {
    const data = {
        action: 'message',
        message: message,
    }
    users.forEach(user => {
        let actor = networkingModule.searchActorByUserId(user);
        networkingModule.sendMessage(MSG_CODE.ACTION_SEND_ORDER, data, [actor.actorNr]);
    });

}

export function sendSimpleAction(action, selectedUsers) {
    console.log(action);
    console.log(selectedUsers);

    const data = { action: action, users: selectedUsers };
    hostActionReciever(data);
}

export function setOrbitToSceneObject(uuid, usersId) {
    // console.log('setOrbitToSceneObject', uuid, actorsNumber);
    let actorsNr = [];
    if (usersId.length > 0) {
        usersId.forEach(userId => {
            let userFound = networkingModule.searchActorByUserId(userId);
            actorsNr.push(userFound.actorNr);
        });
    }

    if (usersId.length > 0) {
        networkingModule.sendMessage(MSG_CODE.ACTION_ORBIT_SCENEOBJECT, uuid, actorsNr);
    } else {
        networkingModule.sendMessage(MSG_CODE.ACTION_ORBIT_SCENEOBJECT, uuid);
    }
}

// export function actionResolve(msg) {
//     actionPromises[msg.uuid].resolve(msg.data);
// }

// export function actionReject(msg) {
//     actionPromises[msg.uuid].reject(msg.data);
// }

