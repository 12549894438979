import { CommonModule, KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { Closable } from '@core/interfaces/closable';
import { I18nService } from '@core/services/i18n.service';
import { fadeInOut } from '@shared/utils/animations';
import { DEFAULT_LANGUAGE, LOCAL_STORAGE_KEYS } from '@shared/utils/constants';

@Component({
  selector: 'app-select-option',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, CommonModule],
  templateUrl: './select-option.component.html',
  styleUrl: './select-option.component.css',
  animations: [fadeInOut]
})
export class SelectOptionComponent extends Closable implements OnInit {
  @Input() title = 'Alert';
  shouldTranslate: boolean = false;
  options: KeyValue<string, string>[] = [];

  formGroup!: FormGroup;
  loading: boolean = false;
  showOnCenter: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private i18nService: I18nService
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      value: ['', Validators.required]
    });

    if (this.shouldTranslate == undefined) this.shouldTranslate = false;

   //  const language = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE) || DEFAULT_LANGUAGE;
   //  this.i18nService.setLocale(language);
  }

  closeModal() {
   this.result.next(null);
   this.close();
  }

  async select() {
    if (this.formGroup.invalid) return;

    try {
      this.loading = true;
      this.result.next(this.formGroup.controls['value'].value);
      this.close();
    } catch (error) {
      console.error(error);
    }
  }
}
