import { v4 as uuidv4 } from "uuid";
import {
    getSelectedSceneObject,
    getNavigationRaycast,
    forceUnselectSceneObject,
    orbitToSceneObject,
    exitOrbitMode,
    setCurrentSceneObject,
    getCurrentOrbitUuid,
    getCurrentFocusUuid,
    focusToSceneObject,
    saveHistoryCreateState,
    saveHistoryDeleteState,
    setEnableTransformControls,
    resetSceneObjectScale
} from "./navigationModuleController";
import {
    sendOnSceneObjectCreateOrUpdate,
    sendDeleteSceneObjectFromNetwork,
    sendVideoStatusToProjectorByNetwork,
    saveScreenShareInfoOnRooomProperty,
    getVideoProgressFromRoomProperty,
    saveVideoProgressInRoomProperty,
    sendForceStopScrenSharingMessage,
    sendAnimationModelActionByNetwork
} from "./networkingModuleController";
import {
    deleteSceneObject,
    loadGalleryLODObject,
    createInstancedMeshLODSceneObject,
    createInstancedMeshLODSceneObjectFromDB,
    createSceneObjectByBuffers,
    createSceneObjectDisplay,
    placeSceneObjectDisplay,
    placeSceneObjectAnimatedModel,
    placeImageSceneObject,
    placeLoadingObject,
    placeSceneObject,
    remoteUpdateSceneObject,
    onSceneObjectSelect,
    onSceneObjectUpdated,
    resetProjector,
    remoteDeleteSceneObject,
    setVideoTextureToprojector,
    setRoomElementsForOrbit,
    createNotFoundObject,
    loadTextureForImageSceneObject,
    setSceneObjectLock,
    forceDisposePreview,
    createSceneObjectAnimatedModel,
    playAnimationModel,
    checkIfSceneObjectIsOrbitable,
    getSceneObjectAnimations,
    loadImageSceneObjectNotFound,
    createAnimatedModelSceneObjectNotFound,
    createProjectorSceneObjectNotFound
} from "./viewerModuleController";
import { MSG_CODE } from "../constants";
import { screenShare, stopScreenShare, communicationSubscribe } from "./communicationModuleController";
import { Vector3 } from "three";
import { isIOSDevice, getSpecs } from "../utils";


let options = {};
let objectsModule;
let viewerModule;
let navigationModule;

const nearFactor = 2.0;

export let objectsWorkerPromises = {};
const sceneObjects = {};

var sceneObjectVideoTemp = undefined;
var displayTypeAction = undefined;

var currentScreenShareData = null;
var currentVideoLoadedData = null;
let specs;

export function initObjectsModule(_options) {
    return new Promise(resolve => {
        options = _options;

        specs = options.rendererOptions.specs ? options.rendererOptions.specs : getSpecs();

        objectsModule = options.modules.objectsModule;
        viewerModule = options.modules.viewerModule;
        objectsModule.init(options, specs).then(() => {
            resolve();
        });

        communicationSubscribe("stop-screen", () => {
            stopShareNavivePopup();
        });

    });
}

export function initObjectsOffscreen(_options) {
    options = _options;
    objectsModule = options.modules.objectsModule;
    viewerModule = options.modules.viewerModule;
    navigationModule = options.modules.navigationModule;
}

export function getGalleryObjects(filter = null) {
    return objectsModule.getGalleryObjects(filter);
}

export function getOrbitableObjectsOnTheScene() {
    return objectsModule.getOrbitableObjectsOnTheScene();
}

export function registerSceneObject(data) {
    return objectsModule.registerSceneObject(data);
}

export function UpdateSceneObjectData(uuid, data) {
    return objectsModule.UpdateSceneObjectData(uuid, data);
}

// on Offscreen context
export function onSelectSceneObject(sceneObject) {

    onSceneObjectSelect(sceneObject);
    var animations = getSceneObjectAnimations(sceneObject);

    // const sceneObjectData = {
    //     uuid: sceneObject ? sceneObject.userData.uuid : null
    // }

    let msg = {
        code: MSG_CODE.SCENE_OBJECT_ON_SELECT,
        data: {
            uuid: sceneObject ? sceneObject.userData.uuid : undefined,
            animations
        }
    }

    if (sceneObject != undefined && sceneObject.exists != undefined) {
        msg.data.exists = sceneObject.exists;
    }
    postMessage(msg);
}

export function onSceneObjectUpdate(sceneObject) {
    // console.log('onSceneObjectUpdate', sceneObject);
    onSceneObjectUpdated(sceneObject);

    let msg = {
        code: MSG_CODE.SCENE_OBJECT_REGISTER_UPDATE,
        data: {
            data: {
                position: [
                    sceneObject.position.x,
                    sceneObject.position.y,
                    sceneObject.position.z],
                rotation: [
                    sceneObject.rotation.x,
                    sceneObject.rotation.y,
                    sceneObject.rotation.z],
                scale: [
                    sceneObject.scale.x,
                    sceneObject.scale.y,
                    sceneObject.scale.z]
            },
            uuid: sceneObject.userData.uuid
        }
    }
    postMessage(msg);
}

export function getSceneObject(uuid) {
    return objectsModule.getSceneObject(uuid);
}

export function refreshToken(newToken) {
    objectsModule.refreshToken(newToken);
}

export async function setHandleSceneSceneObjectAction(obj) {
    // console.log(obj);
    var scenObjectUuid = obj.scenObjectUuid;
    var _selectedSceneObject = objectsModule.getSceneObject(scenObjectUuid);

    switch (obj.action) {
        case "projector":
            if (_selectedSceneObject) {
                var metadata = {
                    isPlaying: true,
                }

                switch (_selectedSceneObject.typeDisplay) {
                    case "video":

                        removeVideo(_selectedSceneObject);
                        break;
                }
                await forceStopVideo(_selectedSceneObject);
                await VerifyAndforceStopScreenSharing();

                switch (displayTypeAction) {
                    case "video":

                        metadata.assetId = sceneObjectVideoTemp.assetId;
                        metadata.owner = sceneObjectVideoTemp.owner;
                        metadata.ownerId = sceneObjectVideoTemp.ownerId;
                        metadata.muted = true;
                        metadata.loop = true;

                        sendVideoStatusToProjectorByNetwork({ uuid: _selectedSceneObject.uuid, status: 'new', metadata, owner: objectsModule.userId })
                        objectsModule.registerVideoToProyector(_selectedSceneObject.uuid, metadata, "video").then((sceneObject) => {

                            if (sceneObject.metadata) {
                                switch (sceneObject.typeDisplay) {
                                    case "video":
                                        forceStopVideo(sceneObject).then(() => {
                                            loadVideo(sceneObject);
                                        });

                                        break;
                                }
                            }
                            sceneObjectVideoTemp = undefined;
                        });
                        break;
                    case "screenShare":
                        objectsModule.getObjectById(_selectedSceneObject.assetId, _selectedSceneObject.owner, _selectedSceneObject.ownerId).then((projectorData) => {
                            if (projectorData) {
                                var displayMaterialName = projectorData.data.displayMaterialName;
                                var uuid = _selectedSceneObject.uuid;
                                screenShare().then((videoElement) => {
                                    sendVideoTextureToProjector(videoElement, displayMaterialName, uuid, _selectedSceneObject.assetId, false);
                                    currentScreenShareData = {
                                        uuid,
                                        displayMaterialName,
                                        userID: options.userOptions.userId,
                                        sceneobject: _selectedSceneObject
                                    }
                                    _selectedSceneObject.metadata = metadata;
                                    _selectedSceneObject.owner = objectsModule.userId;
                                    _selectedSceneObject.typeDisplay = "screenShare";
                                    saveScreenShareInfoOnRooomProperty({ displayMaterialName, uuid, assetId: _selectedSceneObject.assetId, active: true, owner: objectsModule.userId });
                                    sendVideoStatusToProjectorByNetwork({ uuid: _selectedSceneObject.uuid, status: 'share', displayMaterialName, currentScreenShareData, owner: objectsModule.userId });
                                });
                            }
                        });
                        break;
                }
            }
            break;
        case "orbitable":
            var data = _selectedSceneObject;
            // orbitToSceneObject({ data: { uuid: data.uuid } }).then();
            // setRoomElementsForOrbit({ data: { uuid: data.uuid, isVisible: false } }).then();

            let msg = {
                code: MSG_CODE.ACTION_ORBIT_SCENEOBJECT,
                data: {
                    uuid: data.uuid
                }
            }
            options.callbacks.defaultResponse(msg);

            break;
    }
}

export function setOrbitToSceneObjectRemotePlayer(uuid) {
    return new Promise((resolve, reject) => {
        const sceneObject = getSceneObject(uuid);

        // Check if guest is in focus mode
        getCurrentFocusUuid().then((focusData) => {
            const currentFocusUuid = focusData.res;
            if (currentFocusUuid) {
                // console.log('remote currently in focus mode, exit it and enter orbit action');
                // Exit focus mode
                exitOrbitMode({ data: { uuid: currentFocusUuid } }).then(() => {
                    setRoomElementsForOrbit({ data: { uuid: currentFocusUuid, isVisible: true } }).then(() => {
                        // Do orbit action
                        doOrbit(sceneObject, uuid).then((res) => {
                            resolve(res);
                        });
                    });
                });
            } else {
                // Check if guest is in orbit mode
                getCurrentOrbitUuid().then((data) => {
                    const currentOrbitUuid = data.res;

                    // Already in orbit mode with the same sceneObject
                    if (currentOrbitUuid === uuid) {
                        // console.log('SceneObject already in orbit mode');
                        resolve(false);
                        return;
                    }

                    // If already in orbit mode but with another sceneObject, exit orbit mode then switch to the new sceneObject
                    if (currentOrbitUuid) {
                        exitOrbitMode({ data: { uuid: currentOrbitUuid } }).then(() => {
                            setRoomElementsForOrbit({ data: { uuid: currentOrbitUuid, isVisible: true } }).then(() => {
                                doOrbit(sceneObject, uuid).then((res) => {
                                    resolve(res);
                                });
                            });
                        });
                    } else {
                        doOrbit(sceneObject, uuid).then((res) => {
                            resolve(res);
                        });
                    }
                });
            }
        });
    });
}

function doOrbit(sceneObject, uuid) {
    return new Promise((resolve, reject) => {
        checkIfSceneObjectIsOrbitable({ data: sceneObject }).then((res) => {
            let isOrbitable = res;
            if (isOrbitable) {
                setCurrentSceneObject({ data: { uuid: uuid } }).then(() => {
                    orbitToSceneObject({ data: { uuid: uuid } }).then();
                    setRoomElementsForOrbit({ data: { uuid: uuid, isVisible: false } }).then();
                });
            }
            resolve(isOrbitable);
        });
    })
}


export function screenShareAction() {
    displayTypeAction = 'screenShare';
    getSelectedSceneObject().then((data) => {
        const obj = (data != undefined) ? getSceneObject(data.uuid) : undefined;

        if (obj && obj.data.isDisplay) {
            setHandleSceneSceneObjectAction({ scenObjectUuid: obj.uuid, action: "projector" });
        } else {
            var projectors = objectsModule.projectorsAvailableOnTheScene();
            var data = {
                code: "sceneProjector",
                data: projectors
            }
            options.callbacks.defaultResponse(data);
        }
    });
}

export function loadSceneObject(obj) {
    return new Promise(async (resolve, reject) => {
        const sceneObject = await objectsModule.requestSceneObject(obj.id);
        if (!sceneObject) resolve();


        switch (sceneObject.type) {
            case 'video':
                sceneObjectVideoTemp = sceneObject;
                displayTypeAction = 'video';

                getSelectedSceneObject().then((data) => {

                    const obj = (data != undefined) ? getSceneObject(data.uuid) : undefined;

                    if (obj && obj.data.isDisplay) {
                        setHandleSceneSceneObjectAction({ scenObjectUuid: obj.uuid, action: "projector" });
                    } else {
                        var projectors = objectsModule.projectorsAvailableOnTheScene();
                        var data = {
                            code: "sceneProjector",
                            data: projectors
                        }
                        options.callbacks.defaultResponse(data);
                    }
                });

                break;
            case 'image':
                loadImageSceneObject(sceneObject).then(resolve).catch(reject);
                break;
            case 'model':
                //iOS use middle LOD 
                if (!sceneObject.data.animated) {
                    const newSceneObject = filterSceneObjectDataUris(sceneObject);
                    loadModelSceneObject(newSceneObject).then(resolve).catch(reject);
                } else {
                    loadAnimatedSceneObject(sceneObject).then(resolve).catch(reject);
                }
                break;
            // case 'animated':
            //     loadAnimatedSceneObject(sceneObject).then(resolve).catch(reject);
            //     // const newSceneObject = filterSceneObjectDataUris(sceneObject);
            //     // loadModelSceneObject(newSceneObject).then(resolve).catch(reject);
            //     break;
        }
    })
}

const images = {};

function loadImageSceneObject(sceneObject) {
    return new Promise((resolve, reject) => {
        const seed = uuidv4();
        loadTextureForImageSceneObject(sceneObject).then(() => {

        }).catch(e => {
            console.error(e);
        })

        getNavigationRaycast().then(intersection => {
            placeLoadingObject({ data: [{ uuid: sceneObject.uuid, position: intersection.point, normal: intersection.normal }] }).then(() => {
                var registerData = {
                    assetId: sceneObject.assetId,
                    uuid: sceneObject.uuid,
                    seed,
                    owner: sceneObject.owner,
                    ownerId: sceneObject.ownerId,
                    type: sceneObject.type,
                    data: {
                        name: sceneObject.data.name,
                        position: intersection.point,
                        rotation: [0, 0, 0],
                        normal: intersection.normal,
                        scale: [1, 1, 1],
                        isLocked: false,
                        type: sceneObject.type,
                    }
                }

                images[sceneObject.uuid] = registerData;

                const image = images[sceneObject.uuid];
                const message = {
                    data: {
                        uuid: image.uuid,
                        objectPosition: image.data,
                        assetId: image.assetId
                    }
                }

                placeImageSceneObject(message).then((data) => {
                    registerData.data.position = data.res.position;
                    registerData.data.rotation = data.res.rotation;
                    images[sceneObject.uuid].data.position = data.res.position;

                    saveHistoryCreateState(registerData).then();

                    objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                        sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                        sendCallbacks(sceneObjectInDB, "add");
                        resolve();
                    })
                });

            }).catch(reject);
        }).catch(reject);
    })
}

function loadModelSceneObject(sceneObject) {
    return new Promise(async (resolve, reject) => {

        const seed = uuidv4();

        if (!sceneObject.data.isDisplay) {
            if (uniques[sceneObject.assetId]) {
                //cached sceneObject

                const d = {
                    uuid: sceneObject.uuid,
                    assetId: sceneObject.assetId,
                    cached: true,
                    seed,
                    specs
                }

                createInstancedMeshLODSceneObject(d).then(() => {

                }).catch(e => {
                    console.log('Error trying to create cached scene object', e);
                })

            } else {
                //object is not loaded
                loadGalleryLODObject({ uris: sceneObject.data.uris }).then((buffers) => {

                    uniques[sceneObject.assetId] = { groups: {}, assetId: sceneObject.assetId };
                    const d = {
                        uuid: sceneObject.uuid,
                        assetId: sceneObject.assetId,
                        cached: false,
                        buffers: buffers,
                        seed,
                        specs
                    }

                    createInstancedMeshLODSceneObject(d).then(() => {
                    }).catch(e => {
                        console.log('Error trying to create scene object', e);

                    })
                });
            }
        } else {
            // console.log('loadModelSceneObject Display', sceneObject);    
            loadGalleryLODObject({ uris: sceneObject.data.uris }).then((buffers) => {
                const displayMaterialName = sceneObject.data.displayMaterialName
                const message = {
                    data: {
                        uuid: sceneObject.uuid,
                        assetId: sceneObject.assetId,
                        buffers,
                        displayMaterialName,
                        specs
                    }
                }

                createSceneObjectDisplay(message).then(() => {
                    // console.log('back createSceneObjectDisplay');                    
                });

            }).catch(e => {
                console.log(e);
            });
        }

        getNavigationRaycast().then(intersection => {
            //Colocar Rotación dependiendo de la normal del raycast            
            placeLoadingObject({ data: [{ uuid: sceneObject.uuid, position: intersection.point, normal: intersection.normal }] }).then(() => {
                var registerData = {
                    assetId: sceneObject.assetId,
                    uuid: sceneObject.uuid,
                    seed,
                    owner: sceneObject.owner,
                    ownerId: sceneObject.ownerId,
                    data: {
                        name: sceneObject.data.name,
                        position: intersection.point,
                        rotation: [0, 0, 0],
                        normal: intersection.normal,
                        scale: [1, 1, 1],
                        isLocked: false,

                    }
                }

                if (sceneObject.data.isDisplay) {
                    registerData.data.isDisplay = sceneObject.data.isDisplay;
                    registerData.data.type = 'display';
                    displays[sceneObject.uuid] = registerData;

                    const display = displays[sceneObject.uuid];
                    const displayMaterialName = sceneObject.data.displayMaterialName;
                    const message = {
                        data: {
                            uuid: display.uuid,
                            objectPosition: display.data,
                            assetId: display.assetId,
                            displayMaterialName,
                        }
                    }

                    placeSceneObjectDisplay(message).then((data) => {
                        registerData.data.position = data.res.position;
                        registerData.data.rotation = data.res.rotation;
                        displays[sceneObject.uuid].data.position = data.res.position;

                        saveHistoryCreateState(registerData).then();

                        objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {

                            sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                            sendCallbacks(sceneObjectInDB, "add");
                            resolve();
                        })
                    });

                } else {
                    console.log("ES UN UNIQUE");

                    placeSceneObject({ data: { uuid: sceneObject.uuid, assetId: sceneObject.assetId, position: intersection.point, normal: intersection.normal, seed } }).then((data) => {
                        registerData.data.position = data.res.position;
                        registerData.data.rotation = data.res.rotation;
                        registerData.data.type = 'unique';

                        saveHistoryCreateState(registerData).then();

                        objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                            sceneObjectInDB.seed = seed;
                            sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                            sendCallbacks(sceneObjectInDB, "add");
                            resolve();
                        });
                    }).catch((e) => {
                        uniques[sceneObject.assetId] = { groups: {}, assetId: sceneObject.assetId, seed };

                        addObjectPosition(sceneObject.assetId, uniques[sceneObject.assetId].groups, { position: registerData.data.position, name: registerData.data.name, rotation: [0, 0, 0], scale: registerData.data.scale, uuid: sceneObject.uuid }, seed);

                        delete uniques[sceneObject.assetId].buffers;
                        const notFound = {
                            data: uniques[sceneObject.assetId],
                        }

                        createNotFoundObject(notFound).then(() => {
                            objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                                sceneObjectInDB.status = "removed";
                                sceneObjectInDB.seed = seed;
                                sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                                sendCallbacks(sceneObjectInDB, "add");
                                // reject();
                            });

                        });
                    });
                }


            }).catch(reject);
        }).catch((e) => {
            forceDisposePreview().then();
            forceUnselectSceneObject().then();

            reject(e);
        });
    })
}

function loadAnimatedSceneObject(sceneObject) {
    return new Promise((resolve, reject) => {
        //TODO: Cache for animated
        const seed = uuidv4();

        console.log(sceneObject.data.uris);

        loadGalleryLODObject({ uris: sceneObject.data.uris }).then((buffers) => {
            const message = {
                data: {
                    uuid: sceneObject.uuid,
                    assetId: sceneObject.assetId,
                    buffers,
                    specs
                }
            }

            createSceneObjectAnimatedModel(message).then(() => {
                console.log('[createSceneObjectAnimatedModel finished]');
            });

            getNavigationRaycast().then(intersection => {
                placeLoadingObject({ data: [{ uuid: sceneObject.uuid, position: intersection.point, normal: intersection.normal }] }).then(() => {
                    var registerData = {
                        assetId: sceneObject.assetId,
                        uuid: sceneObject.uuid,
                        seed,
                        owner: sceneObject.owner,
                        ownerId: sceneObject.ownerId,
                        data: {
                            name: sceneObject.data.name,
                            position: intersection.point,
                            rotation: [0, 0, 0],
                            normal: intersection.normal,
                            scale: [1, 1, 1],
                            isLocked: false
                        }
                    }

                    const message = {
                        data: {
                            uuid: sceneObject.uuid,
                            objectPosition: registerData.data,
                            assetId: sceneObject.assetId,
                        }
                    }

                    placeSceneObjectAnimatedModel(message).then((data) => {
                        registerData.data.position = data.res.position;
                        registerData.data.rotation = data.res.rotation;
                        registerData.data.type = 'animated';

                        saveHistoryCreateState(registerData).then();

                        objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                            sceneObjectInDB.seed = seed;
                            console.log(registerData);
                            console.log(sceneObjectInDB);
                            sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                            sendCallbacks(sceneObjectInDB, "add");
                            resolve();
                        })
                    });
                });
            });

        }).catch((e) => {
            forceDisposePreview().then();
            forceUnselectSceneObject().then();
            reject(e);
        })

    })
}

export function loadSceneObjectFromNetwork(sceneObject) {

    return new Promise((resolve, reject) => {
        objectsModule.requestSceneObject(sceneObject.assetId, sceneObject.owner, sceneObject.ownerId).then((ogGalleryObject) => {
            //iOS use middle LOD
            let galleryObject = filterSceneObjectDataUris(ogGalleryObject);

            if (sceneObject.data.type == "image") {
                const d = {
                    data: galleryObject.data,
                    uuid: sceneObject.uuid,
                    fromNetwork: true,
                }

                loadTextureForImageSceneObject(d).then(() => {

                }).catch(e => {
                    console.error(e);
                })
            }

            if (sceneObject.data.type == "unique") {
                if (uniques[sceneObject.assetId]) {
                    //cached sceneObject
                    const d = {
                        uuid: sceneObject.uuid,
                        assetId: sceneObject.assetId,
                        cached: true,
                        seed: sceneObject.seed,
                        fromNetwork: true,
                        specs
                    }
                    createInstancedMeshLODSceneObject(d).then(() => {
                        // console.log('created Instanced Mesh LOD Scene Object');
                    }).catch((e) => {
                        console.log('error cache', e);

                    })

                } else {

                    //object is not loaded
                    loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                        uniques[galleryObject.assetId] = { groups: {}, assetId: galleryObject.assetId };
                        const d = {
                            uuid: sceneObject.uuid,
                            assetId: sceneObject.assetId,
                            cached: false,
                            buffers: buffers,
                            seed: sceneObject.seed,
                            fromNetwork: true,
                            specs
                        }

                        createInstancedMeshLODSceneObject(d).then(() => {

                        }).catch((e) => {
                            console.log('error no cache', e);
                        });
                    }).catch(e => {
                        console.log('error', e);
                    });
                }


            }

            if (sceneObject.data.type == "animated") {

                loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                    const message = {
                        data: {
                            uuid: sceneObject.uuid,
                            assetId: sceneObject.assetId,
                            buffers,
                            specs
                        }
                    }

                    createSceneObjectAnimatedModel(message).then(() => {
                        console.log('[createSceneObjectAnimatedModel finished]');
                    });
                }).catch(e => {
                    console.log('error', e);
                });
            }

            placeLoadingObject({ data: [{ uuid: sceneObject.uuid, position: sceneObject.data.position, rotation: sceneObject.data.rotation }] }).then(() => {


                if (sceneObject.data.type == "image") {

                    const message = {
                        data: {
                            uuid: sceneObject.uuid,
                            objectPosition: sceneObject.data,
                            assetId: sceneObject.assetId,
                            fromNetwork: true,
                        }
                    }

                    placeImageSceneObject(message).then((data) => {
                        objectsModule.remoteRegisterSceneObject(sceneObject);
                        sendCallbacks(sceneObject, "add");
                    });
                }

                if (sceneObject.data.type == "unique") {

                    const placeData = {
                        data: {
                            uuid: sceneObject.uuid,
                            assetId: sceneObject.assetId,
                            position: sceneObject.data.position,
                            rotation: sceneObject.data.rotation,
                            seed: sceneObject.seed,
                            fromNetwork: true
                        },
                    }

                    placeSceneObject(placeData).then(() => {
                        objectsModule.remoteRegisterSceneObject(sceneObject);
                        sendCallbacks(sceneObject, "add");
                        resolve();

                    }).catch((e) => {
                        uniques[sceneObject.assetId] = {
                            groups: {},
                            assetId: sceneObject.assetId,
                            seed: sceneObject.seed,
                        };

                        addObjectPosition(
                            sceneObject.assetId,
                            uniques[sceneObject.assetId].groups,
                            {
                                position: sceneObject.data.position,
                                name: sceneObject.data.name,
                                rotation: sceneObject.data.rotation,
                                scale: sceneObject.data.scale,
                                uuid: sceneObject.uuid,
                            },
                            sceneObject.seed,
                        );

                        delete uniques[sceneObject.assetId].buffers;
                        const notFound = {
                            data: uniques[sceneObject.assetId],
                        };

                        createNotFoundObject(notFound).then(() => {
                            const sceneObjectInDB = sceneObject;
                            sceneObjectInDB.status = "removed";
                            objectsModule.remoteRegisterSceneObject(sceneObject);
                            sendCallbacks(sceneObject, "add");
                        }).catch((e) => {

                            uniques[sceneObject.assetId] = {
                                groups: {},
                                assetId: sceneObject.assetId,
                                seed: sceneObject.seed,
                            };

                            addObjectPosition(
                                sceneObject.assetId,
                                uniques[sceneObject.assetId].groups,
                                {
                                    position: sceneObject.data.position,
                                    name: sceneObject.data.name,
                                    rotation: sceneObject.data.rotation,
                                    scale: sceneObject.data.scale,
                                    uuid: sceneObject.uuid,
                                },
                                sceneObject.seed,
                            );

                            delete uniques[sceneObject.assetId].buffers;
                            const notFound = {
                                data: uniques[sceneObject.assetId],
                            };
                            createNotFoundObject(notFound).then(() => {
                                const sceneObjectInDB = sceneObject;
                                sceneObjectInDB.status = "removed";
                                objectsModule.remoteRegisterSceneObject(sceneObject);
                                sendCallbacks(sceneObjectInDB, "add");
                                // reject();
                            });
                        });
                    });
                }

                if (sceneObject.data.type == "display") {

                    displays[sceneObject.uuid] = sceneObject;
                    const display = displays[sceneObject.uuid];

                    loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                        const displayMaterialName = sceneObject.data.displayMaterialName
                        const d = {
                            data: {
                                uuid: display.uuid,
                                objectPosition: display.data,
                                assetId: display.assetId,
                                buffers,
                                displayMaterialName,
                                fromNetwork: true,
                                specs
                            }
                        }

                        createSceneObjectByBuffers(d).then(() => {
                            objectsModule.remoteRegisterSceneObject(sceneObject);
                            sendCallbacks(sceneObject, "add");
                        });
                    }).catch(e => {
                        console.log(e);
                    });
                }

                if (sceneObject.data.type == "animated") {

                    const message = {
                        data: {
                            uuid: sceneObject.uuid,
                            assetId: sceneObject.assetId,
                            objectPosition: sceneObject.data,
                        }
                    }
                    console.log("Place sceneObject Animated model");

                    placeSceneObjectAnimatedModel(message).then((data) => {
                        objectsModule.remoteRegisterSceneObject(sceneObject);
                        sendCallbacks(sceneObject, "add");
                    });
                }
            });

        }).catch(e => {
            console.log('catch', e);
            reject();
        })
    });
}

export function updateSceneObjectFromNetwork(sceneObject) {
    return new Promise((resolve, reject) => {
        objectsModule.remoteRegisterSceneObject(sceneObject);

        remoteUpdateSceneObject({ data: sceneObject }).then(() => {
            resolve();
        }).catch(reject);


    });
}

export function onDeleteSavedObject(savedData) {
    // console.log('onDeleteSavedObject', savedData);    
    viewerModule.deleteSceneObject(savedData.userData.uuid).then(() => {
        let msg = {
            code: 'deleteSavedObject',
            data: {
                userData: savedData.userData
            }
        }
        postMessage(msg);
    })
}

export function deleteSavedObject(data) {
    objectsModule.deleteSceneObject(data.data.userData.uuid).then(() => {
        sendDeleteSceneObjectFromNetwork(data.data.userData);
        sendCallbacks(data.data.userData.uuid, "remove");
    });
}

export function onUpdateSavedObject(savedData) {
    // console.log('onUpdateSavedObject', savedData);    
    viewerModule.onSceneObjectChange(savedData);
    let msg = {
        code: MSG_CODE.SCENE_OBJECT_REGISTER_UPDATE,
        data: {
            data: {
                position: [
                    savedData.position[0],
                    savedData.position[1],
                    savedData.position[2]],
                rotation: [
                    savedData.rotation[0],
                    savedData.rotation[1],
                    savedData.rotation[2]],
                scale: [
                    savedData.scale[0],
                    savedData.scale[1],
                    savedData.scale[2]]
            },
            uuid: savedData.uuid
        }
    }
    postMessage(msg);
}

export function onCreateSavedObject(savedData) {
    // console.log('onCreateSavedObject', savedData);
    let msg = {
        code: 'loadSavedSceneObject',
        data: {
            savedData
        }
    }
    postMessage(msg);
}

export function loadSavedSceneObject(savedData) {
    // console.log('loadSavedSceneObject', savedData);
    const assetId = savedData.data.savedData.userData.assetId;
    objectsModule.requestSceneObject(assetId).then((assetData) => {
        switch (assetData.type) {
            case 'image':
                loadSavedImageSceneObject(assetData, savedData.data.savedData).then();
                break;
            case 'model':
                if (!assetData.data.animated) {
                    const newSceneObject = filterSceneObjectDataUris(assetData);
                    loadSavedModelSceneObject(newSceneObject, savedData.data.savedData).then();
                } else {
                    loadSavedAnimatedSceneObject(assetData, savedData.data.savedData).then();
                }
                break;
        }
    });
}

function loadSavedImageSceneObject(assetData, savedData) {
    return new Promise((resolve, reject) => {
        // console.log('loadSavedImageSceneObject', assetData, savedData);
        const seed = uuidv4();
        assetData.uuid = savedData.userData.uuid;
        const positionArray = [savedData.position.x, savedData.position.y, savedData.position.z];
        //TODO: Fix rotation data
        let rotationArray;
        if ('_x' in savedData.rotation) {
            rotationArray = [savedData.rotation._x, savedData.rotation._y, savedData.rotation._z];
        } else {
            rotationArray = [savedData.rotation.x, savedData.rotation.y, savedData.rotation.z];
        }

        const scaleArray = [savedData.scale.x, savedData.scale.y, savedData.scale.z];

        placeLoadingObject({ data: [{ uuid: savedData.userData.uuid, position: savedData.position, rotation: rotationArray }] }).then(() => {
            loadTextureForImageSceneObject(assetData).then(() => {

                var registerData = {
                    assetId: assetData.assetId,
                    uuid: assetData.uuid,
                    seed,
                    owner: assetData.owner,
                    ownerId: assetData.ownerId,
                    type: assetData.type,
                    data: {
                        name: assetData.data.name,
                        position: positionArray,
                        rotation: rotationArray,
                        scale: scaleArray,
                        isLocked: false,
                        type: assetData.type,
                    }
                }

                images[assetData.uuid] = registerData;

                const image = images[assetData.uuid];
                const message = {
                    data: {
                        uuid: image.uuid,
                        objectPosition: image.data,
                        assetId: image.assetId
                    }
                }
                // console.log('loadSavedImageSceneObject', assetData, registerData, message);

                placeImageSceneObject(message).then((data) => {
                    registerData.data.position = data.res.position;
                    images[assetData.uuid].data.position = data.res.position;

                    // saveHistoryCreateState(registerData).then();

                    objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                        sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                        sendCallbacks(sceneObjectInDB, "add");
                        resolve();
                    })
                });

            }).catch(e => {
                console.error(e);
                reject(e);
            });
        });
    })
}

function loadSavedModelSceneObject(assetData, savedData) {
    return new Promise(async (resolve, reject) => {
        const seed = uuidv4();
        assetData.uuid = savedData.userData.uuid;

        const positionArray = [savedData.position.x, savedData.position.y, savedData.position.z];
        let rotationArray;
        if ('_x' in savedData.rotation) {
            rotationArray = [savedData.rotation._x, savedData.rotation._y, savedData.rotation._z];
        } else {
            rotationArray = [savedData.rotation.x, savedData.rotation.y, savedData.rotation.z];
        }
        const scaleArray = [savedData.scale.x, savedData.scale.y, savedData.scale.z];

        placeLoadingObject({ data: [{ uuid: savedData.userData.uuid, position: savedData.position, rotation: rotationArray }] }).then(() => {
            let registerData = {
                assetId: assetData.assetId,
                uuid: assetData.uuid,
                seed,
                owner: assetData.owner,
                ownerId: assetData.ownerId,
                data: {
                    name: assetData.data.name,
                    position: positionArray,
                    rotation: rotationArray,
                    scale: scaleArray,
                    isLocked: false
                }
            }

            // console.log('loadSavedModelSceneObject', assetData, registerData);

            if (!assetData.data.isDisplay) {
                if (uniques[assetData.assetId]) {//cached sceneObject
                    const d = {
                        uuid: assetData.uuid,
                        assetId: assetData.assetId,
                        cached: true,
                        seed,
                        specs
                    }
                    createInstancedMeshLODSceneObject(d).then(() => {
                        placeSaved3DObject(registerData, assetData, seed)
                    }).catch(e => {
                        console.log('Error trying to create cached scene object', e);
                    })

                } else {
                    //object is not loaded
                    loadGalleryLODObject({ uris: assetData.data.uris }).then((buffers) => {
                        uniques[assetData.assetId] = { groups: {}, assetId: assetData.assetId };
                        const d = {
                            uuid: assetData.uuid,
                            assetId: assetData.assetId,
                            cached: false,
                            buffers: buffers,
                            seed,
                            specs
                        }

                        createInstancedMeshLODSceneObject(d).then(() => {
                            placeSavedDisplayObject(registerData, assetData);
                        }).catch(e => {
                            console.log('Error trying to create scene object', e);

                        })
                    });
                }
            } else {
                // console.log('loadModelSceneObject Display', sceneObject);    
                loadGalleryLODObject({ uris: assetData.data.uris }).then((buffers) => {
                    const displayMaterialName = assetData.data.displayMaterialName
                    const message = {
                        data: {
                            uuid: assetData.uuid,
                            assetId: assetData.assetId,
                            buffers,
                            displayMaterialName,
                            specs
                        }
                    }

                    createSceneObjectDisplay(message).then(() => {
                        placeSavedDisplayObject(registerData, assetData);
                    });

                }).catch(e => {
                    console.log(e);
                });
            }
        });
    })
}

function placeSaved3DObject(registerData, assetData, seed) {
    // console.log("ES UN UNIQUE");
    placeSceneObject({ data: { uuid: assetData.uuid, assetId: assetData.assetId, position: registerData.data.position, rotation: registerData.data.rotation, seed } }).then((data) => {
        registerData.data.position = data.res.position;
        registerData.data.rotation = data.res.rotation;
        registerData.data.type = 'unique';

        // saveHistoryCreateState(registerData).then();

        objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
            sceneObjectInDB.seed = seed;
            sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
            sendCallbacks(sceneObjectInDB, "add");
        });
    }).catch((e) => {
        uniques[assetData.assetId] = { groups: {}, assetId: assetData.assetId, seed };

        addObjectPosition(
            assetData.assetId,
            uniques[assetData.assetId].groups,
            { position: registerData.data.position, name: registerData.data.name, rotation: registerData.data.rotation, scale: registerData.data.scale, uuid: assetData.uuid },
            seed);

        delete uniques[assetData.assetId].buffers;
        const notFound = {
            data: uniques[assetData.assetId],
        }

        createNotFoundObject(notFound).then(() => {
            objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                sceneObjectInDB.status = "removed";
                sceneObjectInDB.seed = seed;
                sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                sendCallbacks(sceneObjectInDB, "add");
            });

        });
    });
}

function placeSavedDisplayObject(registerData, assetData) {
    registerData.data.isDisplay = assetData.data.isDisplay;
    registerData.data.type = 'display';
    displays[assetData.uuid] = registerData;

    const display = displays[assetData.uuid];
    const displayMaterialName = assetData.data.displayMaterialName;
    const message = {
        data: {
            uuid: display.uuid,
            objectPosition: display.data,
            assetId: display.assetId,
            displayMaterialName,
        }
    }
    // console.log("placeSavedDisplayObject", message);

    placeSceneObjectDisplay(message).then((data) => {
        registerData.data.position = data.res.position;
        displays[assetData.uuid].data.position = data.res.position;

        // saveHistoryCreateState(registerData).then();

        objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
            sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
            sendCallbacks(sceneObjectInDB, "add");
        })
    });
}

function loadSavedAnimatedSceneObject(assetData, savedData) {
    return new Promise((resolve, reject) => {
        assetData.uuid = savedData.userData.uuid;
        const positionArray = [savedData.position.x, savedData.position.y, savedData.position.z];
        let rotationArray;
        if ('_x' in savedData.rotation) {
            rotationArray = [savedData.rotation._x, savedData.rotation._y, savedData.rotation._z];
        } else {
            rotationArray = [savedData.rotation.x, savedData.rotation.y, savedData.rotation.z];
        }
        const scaleArray = [savedData.scale.x, savedData.scale.y, savedData.scale.z];
        const seed = uuidv4();

        placeLoadingObject({ data: [{ uuid: savedData.userData.uuid, position: savedData.position, rotation: rotationArray }] }).then(() => {
            loadGalleryLODObject({ uris: assetData.data.uris }).then((buffers) => {
                const message = {
                    data: {
                        uuid: assetData.uuid,
                        assetId: assetData.assetId,
                        buffers,
                        specs
                    }
                }

                createSceneObjectAnimatedModel(message).then(() => {
                    var registerData = {
                        assetId: assetData.assetId,
                        uuid: assetData.uuid,
                        seed,
                        owner: assetData.owner,
                        ownerId: assetData.ownerId,
                        data: {
                            name: assetData.data.name,
                            position: positionArray,
                            rotation: rotationArray,
                            scale: scaleArray,
                            isLocked: false
                        }
                    }

                    const message = {
                        data: {
                            uuid: assetData.uuid,
                            objectPosition: registerData.data,
                            assetId: assetData.assetId,
                        }
                    }

                    placeSceneObjectAnimatedModel(message).then((data) => {
                        registerData.data.position = data.res.position;
                        registerData.data.rotation = data.res.rotation;
                        registerData.data.type = 'animated';

                        // saveHistoryCreateState(registerData).then();

                        objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                            sceneObjectInDB.seed = seed;
                            sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                            sendCallbacks(sceneObjectInDB, "add");
                        })
                    });
                });


            });
        });
    })
}

export async function sceneObjectAction(action, uuid) {
    const msg = {
        code: MSG_CODE.NAVIGATION_TRANSFORM_CONTROLS_SET_MODE,
        data: {
            action,
        },
    };

    switch (action) {
        case "rotate":
        case "translate":
        case "scale":
            msg.code = MSG_CODE.NAVIGATION_TRANSFORM_CONTROLS_SET_MODE;

            if (options.rendererOptions.useOffscreen) {
                options.workers.offscreenCanvasWorker.postMessage(msg);
            } else {
                // TODO: check
            }

            break;
        case "detail":
            break;
        case "delete":
            getSelectedSceneObject().then((userData) => {
                const obj = getSceneObject(userData.uuid);
                // console.log('delete', obj);
                const isLocked = obj.data.isLocked;
                if (!isLocked) {
                    saveHistoryDeleteState(userData).then(() => {
                        deleteSceneObject({ data: userData.uuid }).then(() => {
                            forceUnselectSceneObject().then();
                            objectsModule.deleteSceneObject(userData.uuid).then(() => {
                                sendDeleteSceneObjectFromNetwork(userData);
                                sendCallbacks(userData.uuid, "remove");
                            });
                        });
                    });
                } else {
                    console.log('Object is locked and cannot be deleted.');
                }
            });

            break;
        case "orbit":
            getSelectedSceneObject().then((data) => {
                orbitToSceneObject({ data: { uuid: data.uuid } }).then();
                setRoomElementsForOrbit({ data: { uuid: data.uuid, isVisible: false } }).then();
            });
            break;
        case "focus":
            getSelectedSceneObject().then((data) => {
                focusToSceneObject({ data: { uuid: data.uuid } }).then();
                setRoomElementsForOrbit({ data: { uuid: data.uuid, isVisible: false } }).then();
            });
            break;

        case "exitOrbit":
            getSelectedSceneObject().then((data) => {
                exitOrbitMode({ data: { uuid: data.uuid } }).then();
                setRoomElementsForOrbit({ data: { uuid: data.uuid, isVisible: true } }).then();
            });
            break;

        case 'copy':
            getSelectedSceneObject().then((userData) => {
                const obj = getSceneObject(userData.uuid);
                // console.log('copy', obj);
                const isLocked = obj.data.isLocked;
                if (!isLocked) {
                    forceUnselectSceneObject().then();
                    loadSceneObject({ id: obj.assetId }).then();
                } else {
                    console.log('Object is locked and cannot be copied.');
                }
            });
            break;
        case 'lock':
            getSelectedSceneObject().then((userData) => {
                const obj = getSceneObject(userData.uuid);
                const isLocked = obj.data.isLocked;
                // console.log('isLocked', isLocked);
                setSceneObjectLock(obj).then((registerData) => {
                    setEnableTransformControls({ data: { enabled: !registerData.res.data.data.isLocked } }).then(() => { });
                    objectsModule.registerSceneObject(registerData.res.data).then((sceneObjectInDB) => {
                        sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                    });
                });
            });
            break;
        case 'resetScale':
            getSelectedSceneObject().then((data) => {
                let registerData = getSceneObject(data.uuid);
                resetSceneObjectScale({ data: { uuid: data.uuid } }).then();
                registerData.data.scale = [1, 1, 1];
                objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                    sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                });
            });
            break;
        case 'pause':
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.isPlaying = false;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'pause' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    pauseVideo(sceneObject);
                });
            });
            break;
        case "info":
            getSelectedSceneObject().then((data) => {
                objectsModule.getObjectById(data.assetId).then(async (assets) => {
                    var response = { uuid: data.uuid };

                    if (assets.metadata?.length > 0) {
                        var metadata = JSON.parse(JSON.stringify(assets.metadata));
                        for (const data of metadata) {
                            switch (data.type) {
                                case "Video":
                                case "Image":
                                case "AR Model":
                                    var url = await objectsModule.getUrlByFileName(data.value);
                                    data.value = url;
                                    break;
                            }
                        }
                        response.metadata = metadata;
                    }
                    sendCallbacks(response, "info");
                }).catch(() => {
                    sendCallbacks({ message: "image no found" }, "error-info");
                });
            });
            break;
        case "play":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.isPlaying = true;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'play', owner: objectsModule.userId });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    playVideo(sceneObject);
                });
            });
            break;

        case "mute":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.muted = true;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'mute' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    mutedVideo(sceneObject);
                });
            });
            break;

        case "unmute":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.muted = false;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'unmute' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    mutedVideo(sceneObject);
                });
            });
            break;

        case "disableLoop":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.loop = false;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'disableLoop' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    loopVideo(sceneObject);
                });
            });
            break;

        case "enableLoop":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.loop = true;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'enableLoop' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    loopVideo(sceneObject);
                });
            });
            break;

        case "stop":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                stopVideoInProjector(obj);
            });
            break;

        case "share":

            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                var metadata = {
                    isPlaying: true
                }

                objectsModule.getObjectById(obj.assetId, obj.owner, obj.ownerId).then(async (projectorData) => {
                    if (projectorData) {
                        await forceStopVideo(obj);
                        await VerifyAndforceStopScreenSharing();
                        var displayMaterialName = projectorData.data.displayMaterialName;
                        var uuid = obj.uuid;
                        screenShare().then((videoElement) => {
                            console.log("load screen sharing local");
                            sendVideoTextureToProjector(videoElement, displayMaterialName, uuid, obj.assetId, false);
                            currentScreenShareData = {
                                uuid,
                                displayMaterialName,
                                userID: options.userOptions.userId,
                                sceneobject: obj
                            }
                            obj.metadata = metadata;
                            obj.typeDisplay = "screenShare";
                            obj.owner = objectsModule.userId;
                            saveScreenShareInfoOnRooomProperty({ displayMaterialName, uuid, assetId: obj.assetId, active: true, owner: objectsModule.userId });
                            sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'share', displayMaterialName, currentScreenShareData, owner: objectsModule.userId });
                        });
                    }
                });

            });
            break;

        case "stopshare":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                stopScreenShareProjector(obj);
            });
            break;

        // case "animated":
        //     getSelectedSceneObject().then((data) => {
        //         const message = {
        //             data: {
        //                 uuid: data.uuid,
        //                 type: "byCicked"
        //             }
        //         }
        //         playAnimationModel(message).then(() => {

        //         });
        //     });


        //     // 
        //     break;

        default:
            break;
    }
}

export function playeAnimationModelByName(animation, isHost) {
    return new Promise((resolve, reject) => {
        getSelectedSceneObject().then(async (data) => {
            const obj = getSceneObject(data.uuid);

            const MSG = {
                data: {
                    uuid: data.uuid,
                    action: "playAnimation",
                    index: animation.index,
                    loopType: animation.loopType
                }
            }

            await new Promise(resolve => setTimeout(resolve, 100));

            if (isHost) {
                obj.playingAnimation = true;

                // if (obj.animation) {
                //     if (obj.animation.data.pauseIndex) {
                //         MSG.data.pauseIndex = obj.animation.data.pauseIndex;
                //         MSG.data.currentTime = obj.animation.data.currentTime;
                //     }
                // }
                console.log(obj);


                objectsModule.registerAnimationAction(MSG.data.uuid, MSG).then((sceneObject) => { });
                sendAnimationModelActionByNetwork(MSG);

                playAnimation(obj, MSG, () => {
                    obj.playingAnimation = false;
                    resolve();
                })

            } else {
                obj.animation = MSG;
                obj.playingLocalAnimation = true;
                playAnimation(obj, MSG, () => {
                    obj.playingLocalAnimation = false;
                    resolve();
                })
            }
        });
    });
}

function playAnimation(obj, MSG, callback) {
    playAnimationModel(MSG).then((data) => {
        if (obj.animation) {
            // if (obj.animation.data.loopType == "loopActive") {
            //     console.log("SIII");

            //     playAnimation(obj, obj.animation, callback);
            // }

            // if (obj.animation.data.loopType == "noLoop") {
            //     callback();
            // }
            if (obj.animation.data.loopType == "loopAll" &&
                obj.animation.data.action == "playAnimation") {
                var currentIndex = obj.animation.data.index;
                var nextAnimationIndex = currentIndex + 1;
                if (nextAnimationIndex >= data.animationCount) {
                    nextAnimationIndex = 0;
                }
                obj.animation.data.index = nextAnimationIndex;
                playAnimation(obj, obj.animation, callback);
            } else {
                callback();
            }
        }
    });
}

export function playAnimationByAction(obj, MSG) {
    obj.animation = MSG;
    obj.playingAnimation = true;
    playAnimation(obj, MSG, () => {
        obj.playingAnimation = false;
    })
}

export function setloopAnimation(animation, isHost) {
    console.log(animation);

    return new Promise((resolve, reject) => {
        getSelectedSceneObject().then((data) => {
            const obj = getSceneObject(data.uuid);
            const MSG = {
                data: {
                    uuid: data.uuid,
                    action: "setloop",
                    index: animation.index,
                    loopType: animation.loopType
                }
            }
            console.log(MSG);
            if (isHost) {
                objectsModule.registerAnimationAction(MSG.data.uuid, { data: { uuid: data.uuid, action: "playAnimation", index: animation.index, loopType: animation.loopType } }).then((sceneObject) => { });
                sendAnimationModelActionByNetwork(MSG);
                console.log(obj);

            } else {
                obj.animation = MSG;
            }
            console.log(obj);

            // playAnimationModel(MSG).then(() => {
            //     resolve();
            // });
        });
    });
}

export function stopAnimationAction(animation, isHost) {
    return new Promise((resolve, reject) => {
        getSelectedSceneObject().then(async (data) => {
            const obj = getSceneObject(data.uuid);
            const MSG = {
                data: {
                    uuid: data.uuid,
                    action: "pauseAnimation",
                    index: animation.index,
                    loopType: animation.loopType
                }
            }
            if (isHost) {
                var _data = await playAnimationModel(MSG);
                obj.playingAnimation = false;
                MSG.data.currentTime = _data.currentTime;
                MSG.data.pauseIndex = animation.index;
                objectsModule.registerAnimationAction(MSG.data.uuid, MSG).then((sceneObject) => { });
                sendAnimationModelActionByNetwork(MSG);
                resolve();
                console.log(obj);

            } else {
                var _data = await playAnimationModel(MSG);
                obj.animation = MSG;
                obj.playingLocalAnimation = false;
                MSG.data.currentTime = _data.currentTime;
                MSG.data.pauseIndex = animation.index;
                resolve();
            }


        });
    });
}

export function updateAnimationModelActionFromNetwork(payload) {

    var obj = getSceneObject(payload.data.uuid);
    if (payload.data.action == "playAnimation") {
        obj.animation = payload;
        obj.playingAnimation = true;
        playAnimation(obj, payload, () => {
            obj.playingAnimation = false;
        })
    }
    if (payload.data.action == "setloop") {
        obj.animation = payload;
    }

    if (payload.data.action == "pauseAnimation") {
        obj.animation = undefined;
        obj.playingAnimation = true;
        playAnimationModel(payload).then((data) => {
            obj.playingAnimation = false;
        })
    }

    console.log(obj);
}

function stopVideoInProjector(obj) {
    if (obj.metadata) {

        removeVideo(obj);
        setTimeout(() => {
            objectsModule.getObjectById(obj.assetId, obj.owner, obj.ownerId).then((projectorData) => {
                var displayMaterialName = projectorData.data.displayMaterialName;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'stop', displayMaterialName });
                resetProjector({ data: { uuid: obj.uuid, displayMaterialName } }).then((sceneObject) => {
                    objectsModule.registerVideoToProyector(obj.uuid, undefined, "none").then((sceneObject) => { });
                });
            });

        }, 500)
    }
}

function stopShareNavivePopup() {
    if (currentScreenShareData) {
        const obj = currentScreenShareData.sceneobject;
        stopScreenShareProjector(obj);
    }
}

function stopScreenSharingBeforeNextAction() {
    return new Promise((resolve, reject) => {

        if (currentScreenShareData) {

            console.log("detener el screen sharing");
            var uuid = currentScreenShareData.uuid;
            const obj = getSceneObject(uuid);
            var displayMaterialName = currentScreenShareData.displayMaterialName;
            stopScreenShare().then(() => {
                stopVideoTexture(obj.uuid);
                resetProjector({ data: { uuid: obj.uuid, displayMaterialName } }).then((sceneObject) => { });
                obj.metadata = undefined;
                obj.typeDisplay = "none";
                currentScreenShareData = null;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'stopshareremote', displayMaterialName, currentScreenShareData });
                resolve();
            });

        } else {
            resolve();
        }
    });

}

function VerifyAndforceStopScreenSharing() {
    return new Promise(async (resolve, reject) => {
        if (currentScreenShareData) {
            if (currentScreenShareData.userID == options.userOptions.userId) {
                await stopScreenSharingBeforeNextAction();
                resolve();
            } else {
                sendForceStopScrenSharingMessage(currentScreenShareData.userID);
                setTimeout(() => {
                    resolve();
                }, 1000);

            }
        } else {
            resolve();
        }
    });

}

export function ReceiveForceStopScrenSharingMessage() {
    stopScreenSharingBeforeNextAction();
}

function stopScreenShareProjector(obj) {
    return new Promise((resolve, reject) => {
        objectsModule.getObjectById(obj.assetId, obj.owner, obj.ownerId).then((projectorData) => {
            var displayMaterialName = projectorData.data.displayMaterialName;
            stopScreenShare().then(() => {
                stopVideoTexture(obj.uuid);
                resetProjector({ data: { uuid: obj.uuid, displayMaterialName } }).then((sceneObject) => { });
                currentScreenShareData = null;
                obj.metadata = undefined;
                obj.typeDisplay = "none";
                obj.owner = undefined;
                saveScreenShareInfoOnRooomProperty({ displayMaterialName, uuid: obj.uuid, assetId: obj.assetId, active: false, owner: undefined });
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'stopshare', displayMaterialName, currentScreenShareData });
                resolve();
            });
        });
    });


}

export function deleteSceneObjectById(uuid) {
    const obj = getSceneObject(uuid);
    const data = {
        uuid,
        assetId: obj.assetId
    }

    deleteSceneObject({ data: uuid }).then(() => {
        forceUnselectSceneObject().then();
        objectsModule.deleteSceneObject(uuid).then(() => {
            sendDeleteSceneObjectFromNetwork(data);
            sendCallbacks(uuid, "remove");
        });
    });
}

export function deleteSceneObjectFromNetwork(data) {
    remoteDeleteSceneObject({ data }).then(() => {
        objectsModule.remoteDeleteSceneObject(data.uuid);
        sendCallbacks(data.uuid, "remove");
    });
}

export async function updateVideoStatusToProjectorNetwork(data) {
    console.table(data);
    // console.log(data);
    const obj = getSceneObject(data.uuid);
    switch (data.status) {
        case "new":

            if (obj.typeDisplay == "video") {
                removeVideo(obj);
            }

            // await VerifyAndforceStopScreenSharing();

            obj.metadata = data.metadata;
            obj.typeDisplay = "video";
            obj.owner = data.owner;
            if (obj.metadata) {
                switch (obj.typeDisplay) {
                    case "video":
                        loadVideo(obj);
                        break;
                }
            }

            break;
        case 'play':
            obj.metadata.isPlaying = true;
            obj.typeDisplay = "video";
            obj.owner = data.owner;
            playVideo(obj);
            break;
        case 'pause':
            obj.metadata.isPlaying = false;
            obj.typeDisplay = "video";
            pauseVideo(obj);
            break;
        case 'stop':
            removeVideo(obj);
            obj.metadata = undefined;
            obj.typeDisplay = "none";
            obj.owner = undefined;
            resetProjector({ data: { uuid: data.uuid, displayMaterialName: data.displayMaterialName } }).then();
            break;

        case "mute":
            obj.metadata.muted = true;
            mutedVideo(obj);
            break;

        case "unmute":
            obj.metadata.muted = false;
            mutedVideo(obj);
            break;

        case "disableLoop":
            obj.metadata.loop = false;
            loopVideo(obj);
            break;

        case "enableLoop":
            obj.metadata.loop = true;
            loopVideo(obj);
            break;

        case "share":
            // console.log("load screen sharing remote");

            obj.metadata = { isPlaying: true };
            obj.owner = data.owner;
            obj.typeDisplay = "screenShare";
            var videoElement = document.getElementById(options.roomOptions.screenShareElementID);
            currentScreenShareData = data.currentScreenShareData;
            sendVideoTextureToProjector(videoElement, data.displayMaterialName, data.uuid, obj.assetId, false);
            // setTimeout(() => {
            //     var screenShareInfo = getScreenShareInfoFromRoomProperty();
            //     console.log(screenShareInfo);
            // }, 1000);
            break;
        case "stopshare":
            currentScreenShareData = data.currentScreenShareData;
            obj.owner = undefined;
            obj.typeDisplay = "none";
            resetProjector({ data: { uuid: obj.uuid, displayMaterialName: data.displayMaterialName } }).then(() => { });
            stopVideoTexture(obj.uuid);
            break;

        case "stopshareremote":
            // currentScreenShareData = data.currentScreenShareData;
            obj.owner = undefined;
            obj.typeDisplay = "none";
            resetProjector({ data: { uuid: obj.uuid, displayMaterialName: data.displayMaterialName } }).then(() => { });
            stopVideoTexture(obj.uuid);
            break;
        default:
            break;
    }

}

const uniques = {};
const sceneObjectsTemp = {};
const displays = {};
const animateds = {};
let sceneObjectLoadedCount = 0;

export function loadSceneObjectsFromDB() {
    return new Promise((resolve, reject) => {

        objectsModule.getSceneObjectsFromDB().then((sceneObjectsInDB) => {

            if (Object.keys(sceneObjectsInDB).length <= 0) {
                var data = {
                    code: "finishLoadingObjectsInTheScene",
                    data: {}
                }
                options.callbacks.defaultResponse(data);
            }

            const loadingData = [];

            //TODO: Restructure code for types video, image, animated, model3d, display, etc

            for (const key in sceneObjectsInDB) {
                if (Object.hasOwnProperty.call(sceneObjectsInDB, key)) {
                    const sceneObject = structuredClone(sceneObjectsInDB[key]);
                    loadingData.push({
                        uuid: sceneObject.uuid,
                        position: sceneObject.data.position,
                        rotation: sceneObject.data.rotation,
                    });

                    if (sceneObject.data.isDisplay) {
                        sceneObject.type = 'display';
                    }

                    if (sceneObject.data.type === 'animated') {
                        sceneObject.type = 'animated';
                    }

                    if (
                        !uniques[sceneObject.assetId] &&
                        sceneObject.type != "display" &&
                        sceneObject.type != "image" &&
                        sceneObject.type != "animated"
                    ) {
                        uniques[sceneObject.assetId] = {
                            groups: {},
                            assetId: sceneObject.assetId,
                            seed: sceneObject.seed,
                            data: sceneObject.data,
                            specs,
                        };
                        sceneObjectsTemp[sceneObject.assetId] = sceneObject;
                    }

                    switch (sceneObject.type) {
                        case "image":
                            images[sceneObject.uuid] = sceneObject;
                            break;

                        case "display":
                            displays[sceneObject.uuid] = sceneObject;
                            break;

                        case "animated":
                            animateds[sceneObject.uuid] = sceneObject;
                            break;

                        default:
                            // console.log('default');
                            addObjectPosition(
                                sceneObject.assetId,
                                uniques[sceneObject.assetId].groups,
                                {
                                    position: sceneObject.data.position,
                                    name: sceneObject.data.name,
                                    rotation: sceneObject.data.rotation,
                                    scale: sceneObject.data.scale,
                                    uuid: sceneObject.uuid,
                                    customName: sceneObject.data.customName || sceneObject.data.name
                                },
                                sceneObject.seed,
                                specs
                            );

                            break;
                    }
                    if (sceneObject.metadata) {
                        switch (sceneObject.typeDisplay) {
                            case "video":
                                forceStopVideo(sceneObject).then(() => {
                                    loadVideo(sceneObject);
                                })

                                break;
                        }
                    }
                }
            }

            placeLoadingObject({ data: loadingData }).then(() => {

            });


            for (const key in animateds) {
                if (Object.prototype.hasOwnProperty.call(animateds, key)) {
                    const animatedObject = animateds[key];
                    objectsModule.requestSceneObject(animatedObject.assetId, animatedObject.owner, animatedObject.ownerId).then(galleryObject => {

                        loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                            const message = {
                                data: {
                                    uuid: animatedObject.uuid,
                                    assetId: animatedObject.assetId,
                                    buffers,
                                    fromNetwork: true,
                                    specs
                                }
                            }
                            createSceneObjectAnimatedModel(message).then(() => {
                                const message = {
                                    data: {
                                        uuid: animatedObject.uuid,
                                        objectPosition: animatedObject.data,
                                        assetId: animatedObject.assetId,
                                    }
                                }
                                placeSceneObjectAnimatedModel(message).then((data) => {

                                    if (animatedObject.animation) {
                                        console.log(animatedObject);
                                        const obj = getSceneObject(animatedObject.uuid);
                                        obj.playingAnimation = true;
                                        playAnimation(obj, animatedObject.animation, () => {
                                            obj.playingAnimation = false;

                                        })
                                    }
                                    storeSceneObject(true, animatedObject.assetId, animatedObject.data.position, animatedObject.data.rotation, animatedObject.data.scale, animatedObject.uuid, animatedObject.data.name, animatedObject.data.customName);
                                    resolve();
                                });
                            });

                        }).catch(e => {
                            console.log(e);
                            storeSceneObject(false, animatedObject.assetId, animatedObject.data.position, animatedObject.data.rotation, animatedObject.data.scale, animatedObject.uuid, animatedObject.data.name, animatedObject.data.customName);
                        });
                    }).catch(e => {
                        const message = {
                            data: {
                                uuid: animatedObject.uuid,
                                objectPosition: animatedObject.data,
                                assetId: animatedObject.assetId,
                            }
                        }
                        createAnimatedModelSceneObjectNotFound(message).then(() => {

                        });
                        storeSceneObject(false, animatedObject.assetId, animatedObject.data.position, animatedObject.data.rotation, animatedObject.data.scale, animatedObject.uuid, animatedObject.data.name, animatedObject.data.customName);
                    });
                }
            }

            for (const key in uniques) {
                if (Object.prototype.hasOwnProperty.call(uniques, key)) {
                    const unique = uniques[key];
                    const sceneObjectTemp = sceneObjectsTemp[key];

                    objectsModule.requestSceneObject(sceneObjectTemp.assetId, sceneObjectTemp.owner, sceneObjectTemp.ownerId).then(ogGalleryObject => {
                        //iOS use middle LOD                        
                        const galleryObject = filterSceneObjectDataUris(ogGalleryObject);

                        loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                            unique.buffers = buffers;
                            const d = {
                                data: unique,
                                buffers: buffers
                            }

                            createInstancedMeshLODSceneObjectFromDB(d).then(() => {

                                for (const key in unique.groups) {
                                    var g = unique.groups[key];
                                    g.positions.forEach(sceneObject => {
                                        storeSceneObject(true, unique.assetId, sceneObject.position, sceneObject.rotation, sceneObject.scale, sceneObject.uuid, sceneObject.name, sceneObject.customName);
                                    });
                                }
                            }).catch(e => {
                                // TODO: falta colocar a función los 3 casos donde se coloca un not found
                                for (const key in unique.groups) {
                                    var g = unique.groups[key];
                                    g.positions.forEach(sceneObject => {
                                        storeSceneObject(false, unique.assetId, sceneObject.position, sceneObject.rotation, sceneObject.scale, sceneObject.uuid, sceneObject.name, sceneObject.customName);
                                    });
                                }

                                delete unique.buffers;
                                const notFound = {
                                    data: unique
                                }
                                console.log('first CreateNotFoundObject');
                                createNotFoundObject(notFound).then(() => {

                                })
                            });

                        }).catch(e => {
                            //Archivo corrupto                          

                            for (const key in unique.groups) {
                                var g = unique.groups[key];
                                g.positions.forEach(sceneObject => {
                                    storeSceneObject(false, unique.assetId, sceneObject.position, sceneObject.rotation, sceneObject.scale, sceneObject.uuid, sceneObject.name, sceneObject.customName);
                                });
                            }

                            delete unique.buffers;
                            const notFound = {
                                data: unique
                            }
                            console.log('second CreateNotFoundObject');
                            createNotFoundObject(notFound).then(() => {

                            })
                        })

                    }).catch(e => {

                        //Asset no existe en galería objects

                        for (const key in unique.groups) {
                            var g = unique.groups[key];
                            g.positions.forEach(sceneObject => {
                                storeSceneObject(false, unique.assetId, sceneObject.position, sceneObject.rotation, sceneObject.scale, sceneObject.uuid, sceneObject.name, sceneObject.customName);
                            });
                        }
                        delete unique.buffers;
                        const notFound = {
                            data: unique
                        }
                        console.log('third CreateNotFoundObject', unique);
                        createNotFoundObject(notFound).then(() => {

                        })
                    });
                }
            }

            for (const key in displays) {
                if (Object.prototype.hasOwnProperty.call(displays, key)) {
                    const display = displays[key];
                    objectsModule.requestSceneObject(display.assetId, display.owner, display.ownerId).then(galleryObject => {

                        loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                            const displayMaterialName = galleryObject.data.displayMaterialName;
                            const d = {
                                data: {
                                    uuid: display.uuid,
                                    objectPosition: display.data,
                                    assetId: display.assetId,
                                    buffers,
                                    displayMaterialName,
                                    fromNetwork: true,
                                    specs
                                }
                            }
                            createSceneObjectByBuffers(d).then((response) => {
                                storeSceneObject(true, display.assetId, display.data.position, display.data.rotation, display.data.scale, display.uuid, display.data.name, display.data.customName);
                            });

                        }).catch(e => {
                            console.log(e);
                            storeSceneObject(false, display.assetId, display.data.position, display.data.rotation, display.data.scale, display.uuid, display.data.name, display.data.customName);
                        });
                    }).catch(e => {
                        console.log(e);
                        const d = {
                            data: {
                                uuid: display.uuid,
                                objectPosition: display.data,
                                assetId: display.assetId,
                                fromNetwork: true,
                                specs
                            }
                        }
                        createProjectorSceneObjectNotFound(d).then((response) => { })
                        storeSceneObject(false, display.assetId, display.data.position, display.data.rotation, display.data.scale, display.uuid, display.data.name, display.data.customName);
                    });
                }
            }

            for (const key in images) {
                if (Object.prototype.hasOwnProperty.call(images, key)) {
                    const image = images[key];
                    objectsModule.requestSceneObject(image.assetId, image.owner, image.ownerId).then(galleryObject => {
                        const d = {
                            data: galleryObject.data,
                            uuid: image.uuid

                        }
                        loadTextureForImageSceneObject(d).then(() => {
                            const message = {
                                data: {
                                    uuid: image.uuid,
                                    objectPosition: image.data,
                                    assetId: image.assetId
                                }
                            }
                            placeImageSceneObject(message).then((data) => {
                                // console.log(image);
                                storeSceneObject(true, image.assetId, image.data.position, image.data.rotation, image.data.scale, image.uuid, image.data.name, image.data.customName);
                            });
                        }).catch(e => {
                            console.error(e);
                            storeSceneObject(false, image.assetId, image.data.position, image.data.rotation, image.data.scale, image.uuid, image.data.name, image.data.customName);
                        })
                    }).catch(e => {
                        const d = {
                            data: { uuid: image.uuid }
                        }

                        loadImageSceneObjectNotFound(d).then((data) => {
                            const message = {
                                data: {
                                    uuid: image.uuid,
                                    objectPosition: image.data,
                                    assetId: image.assetId
                                }
                            }
                            placeImageSceneObject(message).then((data) => {
                            });
                        })
                        storeSceneObject(false, image.assetId, image.data.position, image.data.rotation, image.data.scale, image.uuid, image.data.name, image.data.customName);
                        //     // storeSceneObject(false, display.assetId, display.data.position, display.data.rotation, display.data.scale, display.uuid, display.data.name);
                    });
                }
            }



            resolve();
        }).catch(reject);
    });
}

function storeSceneObject(successfull, assetId, position, rotation, scale, uuid, name, customName) {
    var sceneObjectInfo = {
        assetId: assetId,
        data: {
            position: position,
            rotation: rotation,
            scale: scale,
        },
        uuid: uuid
    }
    if (name) {
        sceneObjectInfo.data.name = name;
    }
    if (customName) {
        sceneObjectInfo.data.customName = customName;
    }
    if (successfull == false) {
        sceneObjectInfo.status = "removed";
    }
    sceneObjectLoadedCount++;
    if (sceneObjectLoadedCount == objectsModule.getSceneObjectsCount()) {
        var data = {
            code: "finishLoadingObjectsInTheScene",
            data: {}
        }
        options.callbacks.defaultResponse(data);
    }
    sendCallbacks(sceneObjectInfo, "add");
}

function addObjectPosition(uuidObject, uniqueObjectGroups, objectPosition, seed = null) {
    let theGroup = null;

    for (const key in uniqueObjectGroups) {
        if (Object.prototype.hasOwnProperty.call(uniqueObjectGroups, key)) {
            const group = uniqueObjectGroups[key];
            const distance = getDistanceBetweenPoints(objectPosition.position, group.centroid);
            if (distance <= nearFactor) {
                theGroup = group;
                break;
            }
        }
    }

    if (theGroup) {
        theGroup.positions.push(objectPosition);
        theGroup.centroid = calculateCentroid(theGroup);
    } else {
        const uuid = uuidv4();
        const newGroup = {
            assetId: uuidObject,
            uuid: seed ? seed : uuid,
            positions: [objectPosition],
            centroid: objectPosition.position
        };

        if (seed)
            uniqueObjectGroups[seed] = newGroup;
        else
            uniqueObjectGroups[uuid] = newGroup;
    }

}


function forceStopVideo(sceneObject) {

    return new Promise((resolve, reject) => {

        if (currentVideoLoadedData != null) {
            if (currentVideoLoadedData.uuid != sceneObject.uuid) {
                console.log(sceneObject.uuid, "||", currentVideoLoadedData);
                var displayMaterialName = currentVideoLoadedData.displayMaterialName;
                var uuid = currentVideoLoadedData.uuid;
                removeVideo(currentVideoLoadedData);
                setTimeout(() => {
                    sendVideoStatusToProjectorByNetwork({ uuid, status: 'stop', displayMaterialName });
                    resetProjector({ data: { uuid, displayMaterialName } }).then((sceneObject) => {
                        objectsModule.registerVideoToProyector(uuid, undefined, "none").then((sceneObject) => { });
                    });
                    resolve();
                }, 500);
            } else {
                resolve();
            }
        } else {
            resolve();
        }
    });

}

async function loadVideo(sceneObject) {

    const [projectorData, videoData] = await Promise.all([
        objectsModule.getObjectById(sceneObject.assetId, sceneObject.owner, sceneObject.ownerId),
        objectsModule.getObjectById(sceneObject.metadata.assetId, sceneObject.metadata.owner, sceneObject.metadata.ownerId)
    ]);

    if (projectorData && videoData) {
        const obj = getSceneObject(sceneObject.uuid);
        obj.metadata.muted = true;

        var displayMaterialName = projectorData.data.displayMaterialName;
        var uri = videoData.data.uris[0];
        var uuid = sceneObject.uuid;
        var data = {
            code: "onLoadVideo",
            data: {
                uri,
                uuid,
                isPlaying: sceneObject.metadata.isPlaying,
                loop: sceneObject.metadata.loop,
                displayMaterialName,
                assetId: sceneObject.assetId,
                muted: obj.metadata.muted
            },
        };

        currentVideoLoadedData = {
            uuid,
            displayMaterialName,
        }
        options.callbacks.defaultResponse(data);
    }
}

export function getCurrentVideoFrame(uuid) {
    var videoTime = getVideoProgressFromRoomProperty(uuid);
    return videoTime;
}

export function playVideoInProjector(video, displayMaterialName, uuid, assetId) {
    sendVideoTextureToProjector(video, displayMaterialName, uuid, assetId, true);
}

function playVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.play();
    }
}

function loopVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.loop = sceneObject.metadata.loop;
    }
}

function mutedVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.muted = sceneObject.metadata.muted;
    }
}

function pauseVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.pause();
    }
}

function removeVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        stopVideoTexture(sceneObject.uuid);
        //   videoElement.parentNode.removeChild(videoElement);

        var data = {
            code: "onRemoveVideo",
            data: {
                uuid: sceneObject.uuid,
            },
        };
        options.callbacks.defaultResponse(data);

        setTimeout(() => {
            saveVideoProgressInRoomProperty();
        }, 200);

    }
}



const activeStreams = new Map();

export function sendVideoTextureToProjector(videoElement, displayMaterialName, uuid, assetId, isVideo) {

    stopVideoTexture(uuid);

    let lastTime = 0;

    var specs = getSpecs();
    var fps = (specs.deviceType == "Mobile" || !specs.info.dedicated) ? 8 : 15;
    const frameInterval = 1000 / fps;

    const captureFrame = async (now) => {
        const timeSinceLastFrame = now - lastTime;

        if (videoElement.readyState >= videoElement.HAVE_CURRENT_DATA && timeSinceLastFrame >= frameInterval) {

            try {

                // if('VideoFrame' in window){
                const frame = new VideoFrame(videoElement, {
                    format: "RGBA",
                    timestamp: videoElement.currentTime * 1000
                });

                // Redimensionar el frame antes de enviarlo
                const resizedFrame = await resizeFrame(frame, isVideo);
                frame.close(); // Liberar memoria del frame original

                const data = {
                    uuid,
                    displayMaterialName,
                    frame: resizedFrame,
                    isVideoFrame: true,
                    assetId
                };

                setVideoTextureToprojector({ data }).catch((err) => {
                    console.error("Error setting video texture to projector:", err);
                });
                // } else {
                //     const scaledCanvas = document.createElement('canvas');
                //     scaledCanvas.width = videoElement.videoWidth;
                //     scaledCanvas.height = videoElement.videoHeight;

                //     const scaledCtx = scaledCanvas.getContext('2d');
                //     if (!scaledCtx) return;

                //     scaledCtx.drawImage(
                //         videoElement,
                //         0, 0, videoElement.videoWidth, videoElement.videoHeight,
                //         0, 0, scaledCanvas.width, scaledCanvas.height
                //     );

                //     createImageBitmap(scaledCanvas).then((imageBitmap) => {

                //         const data = {
                //             uuid,
                //             displayMaterialName,
                //             frame: imageBitmap,
                //             isVideoFrame: false,
                //             assetId
                //         };

                //         setVideoTextureToprojector({ data }).catch((err) => {
                //             console.error("Error setting video texture to projector:", err);
                //         });
                //         // this.worker.postMessage({ imageBitmap }, [imageBitmap]);
                //         // this.quantity++;
                //     });
                // }


                lastTime = now;
            } catch (error) {
                console.error("Error al crear o procesar el VideoFrame:", error);
            }

        }

        if (videoElement.requestVideoFrameCallback) {
            const callbackId = videoElement.requestVideoFrameCallback(captureFrame);
            if (activeStreams.get(uuid)) {
                activeStreams.get(uuid).callbackId = callbackId;
            }
        } else {
            setTimeout(() => captureFrame(performance.now()), frameInterval);
        }
    };

    activeStreams.set(uuid, {
        videoElement,
        callbackId: null
    });

    captureFrame(performance.now());
}

async function resizeFrame(frame, isVideo) {
    try {
        var specs = getSpecs();

        var newHeight = 0;
        if (isVideo == true) {
            if (specs.deviceType == "Mobile") {
                newHeight = 180;
            }
            if (specs.deviceType == "Desktop" && !specs.info.dedicated) {
                newHeight = 420;
            }
            if (specs.deviceType == "Desktop" && specs.info.dedicated) {
                newHeight = 720;
            }
        } else {
            if (specs.deviceType == "Mobile") {
                newHeight = 420;
            }
            if (specs.deviceType == "Desktop" && !specs.info.dedicated) {
                newHeight = 720;
            }
            if (specs.deviceType == "Desktop" && specs.info.dedicated) {
                newHeight = 1080;
            }
        }

        const aspectRatio = frame.displayWidth / frame.displayHeight;
        const newWidth = Math.round(newHeight * aspectRatio);

        const offscreen = new OffscreenCanvas(newWidth, newHeight);
        const ctx = offscreen.getContext("2d");

        ctx.drawImage(frame, 0, 0, newWidth, newHeight);
        frame.close();

        return new VideoFrame(offscreen, { timestamp: frame.timestamp });
    } catch (error) {
        console.error("Error al redimensionar VideoFrame:", error);
        return frame;
    }
}

export function stopVideoTexture(uuid) {
    if (activeStreams.has(uuid)) {
        const stream = activeStreams.get(uuid);
        if (stream.videoElement.requestVideoFrameCallback && stream.callbackId) {
            stream.videoElement.cancelVideoFrameCallback(stream.callbackId);
        }
        activeStreams.delete(uuid);
    }
}

export function stopAllVideoTextures() {
    for (const uuid of activeStreams.keys()) {
        stopVideoTexture(uuid);
    }
    activeStreams.clear();
}

function getDistanceBetweenPoints(point1, point2) {
    return Math.sqrt(
        Math.pow(point1[0] - point2[0], 2) +
        Math.pow(point1[1] - point2[1], 2) +
        Math.pow(point1[2] - point2[2], 2)
    );
}

function calculateCentroid(group) {
    const n = group.positions.length;
    const sumX = group.positions.reduce((acc, objectPosition) => acc + objectPosition.position[0], 0);
    const sumY = group.positions.reduce((acc, objectPosition) => acc + objectPosition.position[1], 0);
    const sumZ = group.positions.reduce((acc, objectPosition) => acc + objectPosition.position[2], 0);
    return [sumX / n, sumY / n, sumZ / n];
}

export function getAssetById(assetId) {
    return new Promise((resolve, reject) => {
        objectsModule.getObjectById(assetId).then((asset) => {
            const assetClone = JSON.parse(JSON.stringify(asset));
            resolve(assetClone);
        }).catch(reject);
    });
}


export function sendCallbacks(response, type) {
    switch (type) {
        case "remove":
            var data = {
                code: "RemoveSceneObjectFromDB",
                data: {
                    uuid: response,
                },
            };
            options.callbacks.defaultResponse(data);
            break;
        case "add":
            var data = {
                code: "AddSceneObjectToDB",
                data: response,
            };
            options.callbacks.defaultResponse(data);
            break;
        case "info":
            var data = {
                code: "onInfoSelectedObject",
                data: response,
            };
            options.callbacks.defaultResponse(data);
            break;
        case "error-info":
            var data = {
                code: "onErrorInfoSelectedObject",
                data: response,
            };
            options.callbacks.defaultResponse(data);
            break;
    }
}

export function navigateToObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            //DOM          
            let validate = window;

            const promiseUuid = uuidv4();
            objectsWorkerPromises[promiseUuid] = { resolve, reject };

            // const object1 = getSceneObject(_msg.uuid);
            // console.log(_msg);

            let msg = {
                code: 'goToSceneObject',
                data: {
                    uuid: _msg.uuid
                },
                uuid: promiseUuid
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {
            // const posObj = new Vector3(_msg.data.position[0], _msg.data.position[1] + 0.5, _msg.data.position[2]);
            // navigationModule.checkIntersectionsAroundObject(posObj);

            // const posObj = new Vector3(_msg.data.position[0], _msg.data.position[1] + 0.5, _msg.data.position[2]);
            // const data = {
            //     uuid: _msg.data.uuid,
            //     position: posObj,
            // }
            navigationModule.navigateToObject(_msg.data).then(() => {

                let msg = {
                    code: 'objectResolve',
                    data: {},
                    uuid: _msg.uuid
                }

                postMessage(msg);

                // resolve(data);
            });
        }
    });
}

function filterSceneObjectDataUris(data) {
    let newData;

    if (specs.deviceType === "Mobile") {

        if (specs.so == "iOS") {
            newData = { ...data };
            newData.data.uris = [data.data.uris[data.data.uris.length - 1]];
        } else {
            newData = { ...data };
            newData.data.uris = [data.data.uris.length > 1 ? data.data.uris[data.data.uris.length - 2] : data.data.uris[data.data.uris.length - 1]];
        }
    }

    if (specs.deviceType === 'Desktop') {

        if (specs.info.dedicated == false) {
            newData = { ...data };
            newData.data.uris = [data.data.uris.length > 1 ? data.data.uris[data.data.uris.length - 2] : data.data.uris[data.data.uris.length - 1]];
        } else {
            newData = data;
        }
    }

    // console.log('Lod data: ', newData.data.name, newData.data.uris);  //TODO: Uncomment

    return newData;
}

export function objectResolve(msg) {
    objectsWorkerPromises[msg.uuid].resolve(msg.data);
}

export function objectReject(msg) {
    objectsWorkerPromises[msg.uuid].reject(msg.data);
}

function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        navigator.vendor.includes("Apple");
}